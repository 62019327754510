import { INotification, INotificationsState } from '@models';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { adapter, NAMESPACE } from '../reducers/notifications';
import { Dictionary } from '@ngrx/entity';

export const selectNotificationsState =
  createFeatureSelector<INotificationsState>(NAMESPACE);

const { selectAll, selectIds, selectEntities } = adapter.getSelectors(
  selectNotificationsState
);

export const selectNotifications = selectAll;

export const selectIsLoading = createSelector(
  selectNotificationsState,
  (state: INotificationsState) => state.isLoading
);
export const selectShowMore = createSelector(
  selectNotificationsState,
  (state: INotificationsState) => state.showMore
);
export const selectUnread = createSelector(
  selectNotificationsState,
  (state: INotificationsState) => state.unread
);

export const selectNotification = createSelector(
  selectEntities,
  (entities: Dictionary<INotification>, props: { id: string }) => {
    return entities[props.id];
  }
);
export const selectLength = createSelector(
  selectIds,
  (ids) => ids?.length || 0
);
